import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import { useParams } from 'react-router-dom'
import { use } from 'i18next';
import useApiUrlTranslation from './useApiUrlTranslation';
import { AnimatedButton } from './Ui/Button';

import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { fadeInOnScroll } from './motion/motionVariants';

const EventsSection = ({ contactCycleLangList }) => {


    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [eventList, setEventList] = useState(null);


    const getEvents = async () => {

        await fetch(`${apiUrlTranslation}?action=getEvents&params= WHERE id_event_ligne IN (SELECT id_event_ligne FROM cycle_lang_event_ligne WHERE id_cycle_lang IN(${contactCycleLangList.map(contactCycleLang => contactCycleLang.cycle_lang.id_cycle_lang)})) AND event_start >= NOW() AND  `) // LIMIT 10
            .then(res => res.json())
            .then(res => {
                console.log(res)
                setEventList(res)
            })
    }


    const { ref, inView } = useInView({
        triggerOnce: false, // L'animation ne se déclenche qu'une seule fois
        threshold: 0.1, // L'animation commence quand 10% de l'élément est visible
    });


    useEffect(() => {

        contactCycleLangList && getEvents();


        if (inView) {
            console.log('in view');
        } else {
            console.log('out of view');
        }

    }, [inView, contactCycleLangList])



    return (<motion.div
        ref={ref}
        whileInView="visible"
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 0, } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.05, delay: 0.5 }}

        className="mt-5">
        <h2 className="dark:text-white tracking-tight   h2 my-5">Mes événements</h2>


        <div className="mt-5 flex  flex-col lg:flex-row space-x-5 justify-between items-center ">
            <div className=" basis-5/6 grid grid-cols-1 md:grid-cols-3 gap-3 w-full  ">

                {eventList && eventList.map((event, index) => {

                    return (<div div key={index} className="group flex space-x-3" >


                        <a className=" bg-neutral-800/10 w-full md:w-full border-x border-y border-neutral-900 hover:bg-neutral-800/20 rounded-lg hover:border-pink-600 flex items-center justify-center"
                            target="_blank"
                            rel="noreferrer"
                            href={`/events/${event.id_event}/`}>

                            <div>
                                <div className="text-white p-3 font-bold">{event.nom}</div>
                                <div className="text-white p-3">{event.event_start}</div>
                            </div>
                        </a>
                    </div>)
                })}

            </div>
            <div><AnimatedButton btnText='Ajouter / modifier' /></div>
        </div>
    </motion.div>

    )

}

const ActionsCollectives = ({ contactCycleLangList }) => {

    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: false, // Animates only once
        threshold: 0.1, // Triggers animation when 10% of the element is in view
    });

    useEffect(() => {
        if (inView) {
            controls.start({
                opacity: 1,
                y: 0,
                transition: { duration: 0.5 },
            });
        }
    }, [controls, inView]);


    return (

        <motion.div
            ref={ref}
            animate={controls}
            initial={{ opacity: 0, y: 50 }}
            style={{


            }}

            className="mt-5">
            <h2 className="dark:text-white h2 my-5">Mes actions collectives</h2>



            <div className="mt-5 flex space-x-5 justify-between items-center">
                <div className="flex space-x-5 ">

                    {contactCycleLangList && contactCycleLangList.map((contactCycleLangList, index) => {
                        const cycle_lang = contactCycleLangList.cycle_lang;

                        return (<div div key={index} className="flex space-x-3 mt-5" >

                            {cycle_lang.logo_better.small && <div><img src={cycle_lang.logo_better.small} alt="" className="rounded-full w-20 h-20 object-cover" /></div>}
                        </div>)
                    })}
                </div>
                <div><AnimatedButton btnText='Accéder aux outils' /></div>
            </div>
        </motion.div>
    )
}

const Network = ({ contactCycleLangList }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [allContactCycleLangList, setAllContactCycleLangList] = useState(null);

    const [allContactCycleLangListLength, setAllContactCycleLangListLength] = useState(null);


    const getContactCycleLang = async () => {

        const url = `${apiUrlTranslation}?action=getContactCycleLang&filter=id_cycle_lang IN (${contactCycleLangList.map(contactCycleLangList => contactCycleLangList.cycle_lang.id_cycle_lang)}) `

        await fetch(`${url} LIMIT 10`)
            .then(res => res.json())
            .then(res => setAllContactCycleLangList(res))

        await fetch(`${url}&getCount=1`)
            .then(res => res.json())
            .then(res => setAllContactCycleLangListLength(res))

    }


    useEffect(() => {
        contactCycleLangList && getContactCycleLang();
    }, [contactCycleLangList])

    return (
        <div className="mt-5">

            <h2 className="dark:text-white h2 my-5">Les membres de mon ecosystem</h2>

            <div className="flex justify-between items-center">

                {allContactCycleLangList && <div className="flex flex-wrap -space-x-2">
                    {allContactCycleLangList &&
                        allContactCycleLangList
                            .filter(({ contact }) => !contact.photos.small.includes('silhouette'))
                            .slice(0, 10)
                            .map(({ contact }, index) => (
                                contact.photos?.small &&
                                (
                                    <div key={index} className="w-20">
                                        <img
                                            src={contact.photos.small}
                                            alt=""
                                            className="rounded-full w-20 h-20 object-cover"
                                        />
                                    </div>
                                )
                            ))}

                    <div className=" text-2xl flex items-center justify-center w-20 h-20 bg-neutral-700/30 text-white rounded-full">+ {allContactCycleLangListLength}</div>
                </div>
                }

                <div>
                    <AnimatedButton btnText='Modifier / enrichir' />
                </div>
            </div>

        </div>
    )
}
const iconAnimation = {

    animate: (index) => ({
        opacity: 1,
        y: 0,
        transition: {
            delay: 0.05 * index,
        }
    })
}



const Header = ({ contact, contactCycleLangList }) => {

    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: true, // Animates only once
        threshold: 0.1, // Triggers animation when 10% of the element is in view
    });

    useEffect(() => {
        if (inView) {
            controls.start({
                opacity: 1,
                y: 0,
                x: 0,

            });
        }
    }, [controls, inView]);




    if (!contact) return null;

    return (
        <motion.div

            className="flex flex-col md:flex-row space-x-3 mt-5 justify-between">

            <div className="flex space-x-3">
                <div><img src={contact.photos.small} alt="" className="rounded-full w-40 h-40 object-cover" /></div>
                <div>
                    <p className="dark:text-white text-2xl">{contact.prenom} {contact.nom} </p>
                    <p className="dark:text-white text-xl">{contact.fonction}</p>
                    <p className="dark:text-white text-xl">{contact.societe} </p>
                    <p><a className="font-semibold text-sky-500" href="e">Modifier mes coordonnnées et mon profil</a></p>
                </div>

            </div>

            <div className="flex">
                {contactCycleLangList && contactCycleLangList.map((contactCycleLang, index) => {

                    const cycle_lang = contactCycleLang.cycle_lang;

                    return <motion.div

                        ref={ref}
                        custom={index}
                        animate={controls}
                        variants={iconAnimation}
                        transition={{ ease: "linear", delay: 0.05 * index }}
                        initial={{ opacity: 0, x: 60 }}
                        whileInView="animate"
                        style={{}}

                        key={index}>
                        <div className="flex space-x-3 mt-5">
                            <div><img src={cycle_lang.logo_better.small} alt="" className="rounded-full w-20 h-20 object-cover" /></div>

                        </div>
                    </motion.div>
                }

                )}
            </div>
        </motion.div>
    )


}



const Workspace = () => {

    const { idContact } = useParams();

    const [contact, setContact] = useState(null);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [contactCycleLangList, setContactCycleLangList] = useState(null);



    const getContactCycleLang = async () => {

        const url = `${apiUrlTranslation}?action=getContactCycleLang&filter=id_contact=${idContact} AND id_cycle_lang NOT IN (0)`

        await fetch(url)
            .then(res => res.json())
            .then(res => setContactCycleLangList(res))

    }

    const getContact = async () => {

        await fetch(`${apiUrlTranslation}?action=getContact&id_contact=${idContact}`)
            .then(res => res.json())
            .then(res => {
                setContact(res)
                getContactCycleLang(res.id_contact)
                console.log(res);

            })
    }


    useEffect(() => {
        getContact();
    }, [])



    return (
        <Layout>
            <div className="px-5">

                <Header
                    contactCycleLangList={contactCycleLangList}
                    contact={contact} />

                <Network contactCycleLangList={contactCycleLangList} />

                <ActionsCollectives contactCycleLangList={contactCycleLangList} />

                <EventsSection contactCycleLangList={contactCycleLangList} />
            </div>
        </Layout>
    )
}

export default Workspace